import { gql } from "@apollo/client";
import { LISTING_FRAGMENT } from "./listingRecommendation";

export const GET_USER_DATA = gql`
  query GetUserData {
    me {
      id: user_id
      email
      first_name
      last_name
      phone
      wishlists {
        id
      }
    }
  }
`;

export const GET_WISHLIST = gql`
  query GetWishList {
    me {
      id: user_id
      wishlists {
        ...LandingListing
      }
    }
  }
  ${LISTING_FRAGMENT}
`;

export const CREATE_WISHLIST = gql`
  mutation CreateWishList($listingId: Int!) {
    wishlist {
      create(listing_id: $listingId) {
        id
      }
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation createNotification($input: NewNotification!) {
    notification {
      create(input: $input) {
        id
        type
      }
    }
  }
`;

export const DELETE_WISHLIST = gql`
  mutation DeleteWishList($listingId: Int!) {
    wishlist {
      delete(listing_id: $listingId) {
        id
      }
    }
  }
`;

export const REPORT_LISTING = gql`
  mutation ReportListing($input: NewReportListing!) {
    report {
      create_report_listing(input: $input) {
        id
        email
        message
        status
      }
    }
  }
`;

export const SUBMIT_RESET_PASSWORD = gql`
  mutation SubmitResetPassword($email: String!) {
    user {
      forgot_password(email: $email)
    }
  }
`;

export const CHECK_TOKEN_FORGOT = gql`
  mutation CheckTokenResetPassword($hashed: String!) {
    user {
      check_token_forgot_password(hashed: $hashed)
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangeForgetPass($input: ForgotPasswordChangePasswordInput!) {
    user {
      forgot_password_change_password(input: $input)
    }
  }
`;

export const TOKEN_REDIRECT_PANEL = gql`
  query RedirectToPanel {
    token_redirect_panel
  }
`;

export const VIEW_LISTING_ALLOWANCE = gql`
  query viewListingAllowance($ip_address: String!) {
    allow_view_listing(ip_address: $ip_address)
  }
`;

export const SEND_NOTIFICATION = gql`
  mutation sendNotification($input: NewNotification!) {
    notification {
      create(input: $input) {
        id
        type
        fcm_id
        listing_id
        message
        status
        time
        message_id
      }
    }
  }
`;

export const SEND_MEMO = gql`
  mutation sendMemo($input: NewMemo!, $listing_id: ID) {
    memo {
      create(input: $input, listing_id: $listing_id) {
        id
        email
        fullname
        message
        category
        time
        status
      }
    }
  }
`;

export const CHECK_TOKEN_VERIF = gql`
  mutation checkTokenVerif($token: String!) {
    user {
      email_verification_check_token(token: $token) {
        status
        link
      }
    }
  }
`;

export const REQUEST_DEL_ACC = gql`
  mutation requestDelAcc($email: String!) {
    user_delete_request_public {
      request_by_email(email: $email) {
        id
        user_id
        status
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;
