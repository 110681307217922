import React, { useEffect } from "react";
import '../styles/globals.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../apollo-client";
import analytic from "../utils/analytic";
import dynamic from 'next/dynamic'
import Provider from "../context";

const WhatsappButton = dynamic(() => import('../component/common/WhatsappButton'), { ssr: false });

const TopProgressBar = dynamic(
  () => {
    return import("../utils/nprogress");
  },
  { ssr: false },
);


const MyApp = ({ Component, pageProps }) => {
  useEffect(() => {
    analytic.initialize()
  }, [])

  const client = useApollo();

  return (
    <ApolloProvider client={client}>
      <Provider>
        <TopProgressBar />
        <Component {...pageProps} />
        <WhatsappButton />
      </Provider>
    </ApolloProvider>
  );
}

export default MyApp;